import PropTypes from "prop-types"
import React from "react"
import { useColorMode, useThemeUI } from "theme-ui"
import Box from "./box"
import { lighten } from "@theme-ui/color"

const ComicStrip = ({ contents, textBoxes }) => {
  const context = useThemeUI()
  const { theme } = context
  const [colorMode] = useColorMode()

  const backgroundColors = [
    theme.colors["summer"],
    theme.colors["autumn"],
    "#f45683",
    theme.colors["spring"],
    theme.colors["summer"],
    theme.colors["winter"],
  ]

  const textBoxStyles = [
    {
      left: -6,
      top: "-4px",
      py: 2,
      pr: 4,
      pl: 8,
    },
    {
      bottom: "-4px",
      right: -6,
      py: 2,
      pl: 4,
      pr: 8,
    },
    {
      top: "-4px",
      right: -6,
      py: 2,
      pl: 4,
      pr: 8,
    },
    {
      bottom: "-4px",
      left: "-4px",
      py: 2,
      px: 4,
    },
  ]

  const getBackgroundColor = (index) => {
    const i = index % backgroundColors.length
    return backgroundColors[i]
  }

  const getTextBoxStyles = (index) => {
    const emptyBoxesUntilIndex = textBoxes.filter(
      (text, i) => i < index && !text
    ).length
    const i = (index - emptyBoxesUntilIndex) % textBoxStyles.length
    return textBoxStyles[i]
  }

  return (
    <Box
      sx={{
        bg: "white",
        p: colorMode === "light" ? null : 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          ml: -2,
          mb: -2,
        }}
      >
        {contents.map((value, i) => {
          return value ? (
            <Box
              key={`answer-${i}`}
              sx={{
                backgroundImage: (theme) => `radial-gradient(circle,
                  ${lighten(getBackgroundColor(i), 0.2)(theme)},
                  ${lighten(getBackgroundColor(i), 0)(theme)}
                )`,
                borderWidth: 4,
                borderColor: "black",
                display: "flex",
                fontSize: 8,
                ml: 2,
                mb: 2,
                flex:
                  i === 0 ? "1 1 400px" : i === 4 ? "1 1 100px" : "1 1 200px",
                height: "200px",
                position: "relative",
                overflow: "hidden",
                boxShadow: "0 6px 6px -6px #000",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {textBoxes[i] && (
                <Box
                  sx={{
                    bg: "white",
                    borderColor: "black",
                    position: "absolute",
                    borderWidth: 4,
                    color: "black",
                    fontSize: 3,
                    fontStyle: "italic",
                    fontFamily: "sans",
                    ...getTextBoxStyles(i),
                  }}
                >
                  {textBoxes[i]}
                </Box>
              )}
              {value}
            </Box>
          ) : null
        })}
      </Box>
    </Box>
  )
}

ComicStrip.propTypes = {
  contents: PropTypes.array,
  textBoxes: PropTypes.array,
}

ComicStrip.defaultProps = {
  contents: [],
  textBoxes: [
    "Was bisher geschah…",
    "und ich so…",
    null,
    "und dann…",
    null,
    "und dann…",
  ],
}

export default ComicStrip
