import React from "react"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Paragraph from "../../../../../components/paragraph"
import ComicStrip from "../../../../../components/comic-strip"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "tagebuch",
}

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const answer = getAnswer({
    ...taskProps,
    taskId: "reiseerlebnisse",
  })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/tagebuch/aufgabe-1" />
      }
      navigation={navigation}
      progress={progress}
    >
      <Seo title="Das Tagebuch weiterschreiben " />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Hölderlins Tagebuch
          </Heading>
          {answer && <ComicStrip contents={Object.values(answer)} />}
          <Paragraph>
            Wie könnte Hölderlins Reise weiter verlaufen sein? Was entdeckt er
            in dem Schloss? Wo war Blum? Führe den Tagebucheintrag fort und
            erzähle die Geschichte aus Hölderlins Sicht weiter.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
